import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';

export const panelDefinitions = {
  addPagePanel: () => ({
    width: 600,
    height: 525,
    shouldHideHeader: true,
    url: getPanelUrl('panelsDummyWidget', 'AddPage'),
  }),
  pageInfoPanel: () => ({
    title: 'Page Settings',
    url: getPanelUrl('panelsDummyWidget', 'PageInfo'),
  }),
  permissionsPanel: () => ({
    title: 'Page Permissions',
    url: getPanelUrl('panelsDummyWidget', 'Permissions'),
  }),
};
